@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  background-color: inherit;
  @extend %flex-column;

  &__date {
    @extend %title-13;
    margin-top: 16px;
    opacity: 0.4;
  }

  &__text {
    @extend %title-14;
    color: $primary-color;
    margin-top: 24px;

    a {
      @extend %title-14-600;
      color: $primary-active-color;
      text-decoration: underline !important;
    }

    h2 {
      @extend %title-16;
    }

    h3 {
      @extend %title-15;
    }

    i {
      font-style: italic;
    }

    p {
      min-height: 16px;
    }

    strong {
      @extend %title-14-600;
    }
  }

  &__title {
    @extend %title-24-700;
    color: $primary-color;
    line-height: 32px;
  }

  &__img {
    @extend %flex-justify-center;
    border-radius: 8px;
    margin-top: 32px;
    width: 100%;

    img {
      border-radius: inherit;
      object-fit: cover;
      max-width: 600px;
    }
  }
}

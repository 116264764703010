@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  background-color: inherit;
  border-top: 1px solid $border-table-color;
  margin-top: 16px;
  padding: 18px 0;
}

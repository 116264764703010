@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__profile_title {
    .title {
      @extend %title-24;
      color: $primary-color;
      font-weight: 700;
    }

    .email {
      @extend %title-12;
      color: $primary-color;
      margin-top: 6px;
      text-transform: uppercase;
    }
  }

  &__profile_info {
    @extend %flex-column;
    background-color: inherit;
    border-bottom: 1px solid $border-color;
    gap: 24px;
    margin-bottom: 32px;
    padding: 32px 0;

    &__item {
      @extend %flex-column;

      .title {
        @extend %title-13-bold;
      }

      .details {
        @extend %title-12;
        font-weight: 300;
      }
    }

    &__compex_item {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }

  &__settings {
    .title {
      @extend %title-16-bold;
      margin-bottom: 16px;
    }

    .btn_container {
      @extend %flex-align;
      gap: 24px;
    }

    .password_status {
      @extend %title-12;
      color: $fade-color;
      margin-top: 24px;
    }
  }
}

.referralCode {
  display: flex;

  .copy_btn {
    @extend %flex-align;
    @extend %title-12-500;
    color: $primary-active-color;
    cursor: pointer;
    gap: 4px;
    margin-left: 8px;
  }
}

.toaster {
  @extend %title-13;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 300px;
  padding: 16px;
  position: fixed;
  top: 80px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  width: auto;
  z-index: 7;

  &__success {
    border: 0.5px solid #14ab6c;
    color: #14ab6c;
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.copyright {
  @extend %title-13-bold;
  color: $fade-color;
  margin-top: 16px;
  text-align: center;
  width: 100%;
}

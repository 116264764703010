@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__header_block {
    @extend %flex-between;
    color: $primary-color;
    padding-bottom: 32px;
    width: 280px;
  }

  &__table_block {
    border-top: 1px solid $border-table-color;
    padding-top: 32px;
  }
}

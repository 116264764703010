@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  display: flex;
  background-color: inherit;
  justify-content: space-between;
  gap: 22px;

  &__color {
    border-radius: 8px;
    height: 100%;
    opacity: 0.24;
    width: 42px;
  }

  .mint {
    background: rgba(11, 104, 140, 1);
  }

  .powder {
    background: rgba(191, 179, 168, 1);
  }

  .purple {
    background: rgba(208, 102, 67, 1);
  }

  &__content {
    width: 80%;

    .title {
      @extend %flex-align;
      justify-content: space-between;

      h3 {
        @extend %title-16-bold;
        color: $primary-color;
      }

      a {
        @extend %title-13-bold;
        color: $primary-active-color;
      }
    }
  }

  &__value_block {
    display: flex;
    margin-top: 32px;
    min-height: 30px;

    h4 {
      @extend %title-24-700;
      margin-right: 8px;
    }

    span {
      @extend %title-16;
    }
  }
}

@import '../../../../styles/vars.scss';
@import '../../../../styles/fonts.scss';
@import '../../../../styles/local.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/common.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.container {
  @extend %flex-column;
  height: 100vh;
  justify-content: center;
  margin: 0px auto;
  flex-direction: column;
  width: 40%;

  .wrapper {
    @extend %flex-column;
    align-items: center;
    background-color: inherit;
    justify-content: center;
    padding: 8px 46px;

    &__title {
      @extend %title-16-bold;
      color: $primary-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__title_error {
      @extend %title-16-bold;
      color: $error-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__subtitle {
      @extend %title-13;
      color: $primary-color;
      margin-bottom: 24px;
    }

    .go_to {
      @extend %title-13-bold;
      color: $primary-active-color;
      cursor: pointer;
      margin-top: 32px;
      text-align: center;
      width: 100%;
    }
  }
}

.simple_input {
  background: $white;
  border: 1px solid $components-border-color;
  border-radius: 8px;
  color: $primary-color;
  height: 36px;
  padding: 8px 12px;

  &__focus {
    background-color: $background-disabled;
    border-color: $primary-active-color;
  }
}

.input_error {
  background: $white;
  border: 1px solid #eb5746;
  border-radius: 8px;
  color: $primary-color;
  height: 36px;
  padding: 8px 12px;
}

.error {
  color: #eb5746;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}

// Main Colors
$active-tab-background: rgba(39, 129, 119, 0.08);
$background-disabled: #f5f5f5;
$background-img: #f7f8f8;
$border-color: rgba(20, 36, 39, 0.08);
$border-color-light: #e1e1e5;
$border-table-color: #ddeaec;
$color-disabled: #afafaf;
$color-is-disable: #eaeaea;
$components-border-color: #e1e1e1;
$dark-blue: #1f2440;
$electric-sky: #0b688c;
$error-color: rgba(235, 87, 70, 1);
$hover-option-background: rgba(247, 248, 248, 1);
$hover-option-text: rgba(208, 102, 67, 1);
$middle-gray: rgba(20, 36, 39, 0.6);
$fade-color: rgba(20, 36, 39, 0.4);
$primary-active-color: #d06643;
$primary-background: rgba(247, 248, 248, 1);
$primary-color: #010626;
$white: #ffffff;

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  width: 96%;

  &__calendar {
    border-top: 1px solid $border-table-color;
    margin-top: 20px;
    min-height: 700px;
    padding-top: 16px;
  }
}

.event__container {
  background: $electric-sky;
  border-radius: 3px;
  color: $white;
  display: flex;
  flex-direction: column;
  padding: 4px 6px;
  width: 100%;

  &__title {
    @extend %title-12-500;
    overflow: hidden;
    text-transform: capitalize;
    text-overflow: ellipsis;
  }

  &__time {
    @extend %title-12;
    opacity: 0.6;
  }

  &__past_event {
    background: $color-disabled;
    color: $color-is-disable;
  }
}

a {
  border: none !important;
}

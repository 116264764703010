@import '../../../../styles/vars.scss';
@import '../../../../styles/fonts.scss';
@import '../../../../styles/local.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/common.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.action {
  @extend %flex-justify-between;
  @extend %title-14;
  cursor: pointer;
  gap: 4px;
  line-height: 16px;
  position: absolute;
  right: 0;

  span {
    background: transparent;
    border-radius: 8px;
    color: $primary-color;
    padding: 10px 16px;
  }

  svg path {
    fill: $primary-color;
  }

  &__item {
    @extend %flex-align;
    @extend %title-14;
    background: transparent;
    gap: 2px;
    margin: 0;
    padding: 0 4px;

    &:disabled {
      span {
        color: $color-disabled;
      }

      svg path {
        fill: $color-disabled;
      }
    }

    &__active {
      color: $primary-active-color !important;
      font-weight: bold !important;
      border: 1px solid #e1e1e5 !important;
    }
  }
}

%title-10-500 {
  font-family: 'Inter', sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px;
}

%title-11 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
}

%title-12 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

%title-12-500 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

%title-13 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}

%title-13-500 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
}

%title-13-bold {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

%title-14 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

%title-14-600 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

%title-15 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

%title-16 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

%title-16-bold {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

%title-24 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

%title-24-700 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-size: 24px;
  font-weight: 700;
  line-height: 16px;
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  border-right: 1px solid $border-color;
  height: 100vh;
  left: 0;
  overflow: hidden;
  padding-left: 28px;
  padding-right: 28px;
  position: fixed;
  top: 0;
  width: 22%;
  z-index: 2;

  &__logo {
    @extend %flex-center;
    border-bottom: 1px solid $border-color;
    margin-bottom: 38px;
    padding-bottom: 38px;
    padding-top: 38px;
  }

  &__content {
    @extend %flex-column-between;
    height: 80%;
  }
}

.list {
  @extend %flex-align;
  justify-content: left;
  min-height: 48px;
  padding: 10px 12px;

  &__open {
    background-color: $white;
    border-radius: 8px;
  }

  &__item {
    @extend %flex-align;
    border-radius: 8px;
  }

  &__icon {
    @extend %flex-center;
    padding: 16px;

    svg path {
      fill: $primary-color;
    }

    &__open {
      svg path {
        fill: $primary-active-color;
      }
    }
  }

  &__text {
    color: $primary-color;

    &__open {
      color: $primary-active-color;
    }
  }
}

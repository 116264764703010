@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  @extend %title-13-bold;
  border: 1px solid $border-color-light;
  border-radius: 8px;
  color: $primary-color;
  cursor: pointer;
  margin-right: 12px;
  line-height: 18px;
  padding: 8px 16px;
  white-space: nowrap;

  &__selected {
    background-color: $primary-active-color;
    border: 1px solid $primary-active-color;
    color: $white;
  }
}

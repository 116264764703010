// media queries vars
$mobile-m: 375px;
$tablet-m: 576px;
$tablet: 768px;
$tablet-l: 992px;
$desktop: 1024px;
$wide: 1200px;
$wide-l: 1440px;

//Font Families
$primary_family: 'Inter', sans-serif;

// @extends
%flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

%flex-align {
  display: flex;
  align-items: center;
}

%flex-justify-center {
  display: flex;
  justify-content: center;
}

%flex-justify-between {
  display: flex;
  justify-content: space-between;
}

%flex-column {
  display: flex;
  flex-direction: column;
}

%flex-column-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

%flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

%flex-end {
  display: flex;
  justify-content: flex-end;
}

%flex-align-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

%common-arrow-styles {
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(20, 36, 39, 0.06);
  border-radius: 8px;
  cursor: pointer;
  position: absolute;
  padding: 12px;
  padding-bottom: 8px;
  top: 42%;
  z-index: 3;
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: $white;
  border-radius: 12px;
  height: auto;
  justify-content: space-between;
  width: 100%;

  &__header {
    &__navigation {
      @extend %flex-justify-between;
      margin-bottom: 44px;

      &__item {
        @extend %flex-align;
        @extend %title-13-bold;
        color: $primary-active-color;

        svg {
          margin-right: 14px;
        }
      }
    }

    &__title {
      @extend %title-24;
    }
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  &__footer {
    &__item {
      &__calendar_widget {
        display: flex;
        min-height: 102px;

        &__date {
          align-items: center;
          border-right: 1px solid $components-border-color;
          display: flex;
          flex-direction: column;
          min-width: 97px;
          padding-top: 16px;
          padding-right: 16px;

          .date {
            @extend %title-24-700;
            color: $dark-blue;
            margin-top: 8px;
          }

          .day {
            @extend %title-12;
            color: $dark-blue;
            opacity: 0.4;
            text-transform: uppercase;
            margin-bottom: 10px;
          }

          .counter {
            @extend %title-10-500;
            color: $electric-sky;
            margin-top: 18px;
          }
        }

        &__preview_block {
          box-sizing: border-box;
          padding-left: 16px;
          min-width: 84%;
          width: 506px;

          .header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;

            &__week {
              display: flex;
              gap: 14px;

              &__item {
                @extend %title-13-bold;
                color: $dark-blue;
                cursor: pointer;
                padding: 2px 6px;
                position: relative;

                &__selected {
                  border-radius: 8px;
                  background: $primary-active-color;
                  color: $white;
                  padding: 2px 6px;
                }

                &__event_marker {
                  &::after {
                    color: $electric-sky;
                    content: '.';
                    font-size: 38px;
                    position: absolute;
                    right: -5px;
                    text-shadow: 1px 1px 0 $white, 1px -1px 0 $white, -1px 1px 0 $white,
                      -1px -1px 0 $white, 1px 0px 0 $white, 0px 1px 0 $white, -1px 0px 0 $white,
                      0px -1px 0 $white;
                    top: -17px;
                  }
                }
              }
            }

            &__month {
              @extend %title-13;
              color: $primary-color;
              line-height: 18px;
              opacity: 0.8;
              position: absolute;
              right: 10px;
            }
          }
        }
      }
    }
  }
}

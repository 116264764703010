@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.container {
  @extend %flex-column;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0px auto;
  width: 40%;
  .wrapper {
    @extend %flex-column;
    align-items: center;
    background-color: inherit;
    justify-content: center;
    padding: 8px 46px;
    &__img {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 80px;
    }
    &__title {
      @extend %title-16-bold;
      color: $primary-color;
      margin-bottom: 8px;
    }
    &__text {
      @extend %title-13;
      color: $primary-color;
      text-align: center;
    }
    &__btn_container {
      margin-top: 80px;
      width: 240px;
    }
  }
}

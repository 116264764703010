@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: $white;

  &__label {
    @extend %title-13;
    color: $primary_color;
    margin-bottom: 6px;
  }
}
.container {
  @extend %flex-column;
  align-items: center;
  background-color: $white;
  border-radius: 8px;
  box-shadow: 0px 0px 4px rgba(20, 36, 39, 0.05), 0px 5px 15px rgba(20, 36, 39, 0.1);
  justify-content: center;
  padding: 16px;

  &__actions {
    @extend %flex-justify-between;
    align-items: center;
    border-bottom: 1px solid $border-table-color;
    cursor: pointer;
    margin-bottom: 16px;
    padding-bottom: 12px;
    width: 100%;

    &__item {
      @extend %title-13;
      border-radius: 6px;
      color: $primary_color;
      font-weight: 300;
      padding: 4px 8px;

      &__active {
        background-color: $primary-active-color;
        color: $white;
      }
    }
  }
}

.custom_input {
  @extend %flex-justify-between;
  @extend %title-13;
  background: $white;
  border: 1px solid $components-border-color;
  border-radius: 8px;
  height: 36px;
  min-height: 36px;
  min-width: 280px;
  padding: 8px 12px;
  width: 330px;

  &__value {
    color: $primary-color;
  }

  &:hover {
    border-color: $primary-active-color;
  }

  &__open {
    border-color: $primary-active-color;
  }

  &__controls {
    align-items: center;
    display: flex;
    gap: 2px;
  }
}

.placeholder {
  color: $fade-color;
}

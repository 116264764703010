@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.slider_arrow_next {
  @extend %common-arrow-styles;
  box-shadow: 0px 5px 10px 0px rgba(9, 8, 47, 0.07);
  right: -15px;

  &__disabled {
    display: none;
  }
}

.slider_arrow_prev {
  @extend %common-arrow-styles;
  box-shadow: 0px 5px 10px 0px rgba(9, 8, 47, 0.07);
  left: -8px;

  &__disabled {
    display: none;
  }

  svg {
    transform: rotate(180deg);
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  &__header {
    background-color: $white;
    display: table-header-group;

    &__row {
      display: table-header-group;
      border-bottom: 1px solid $border-table-color;

      th {
        text-align: center;

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }

    &__ceil {
      padding: 6px 0px !important;
      text-align: center;
      text-transform: uppercase;
      white-space: nowrap;

      span {
        @extend %title-12;
        color: $primary-color !important;
        max-width: 130px;
        white-space: initial;
      }
    }
  }

  .inner {
    border-collapse: collapse;
    border-style: hidden;
    overflow-x: auto;
  }
}

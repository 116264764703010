@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__header {
    display: flex;

    &__title_block {
      margin-left: 32px;

      .date {
        @extend %title-13;
        opacity: 0.4;
      }

      .title {
        @extend %title-24-700;
        color: $primary-color;
        margin-top: 12px;
        line-height: 32px;
      }

      .time {
        @extend %title-16-bold;
        color: $primary-color;
        margin-top: 6px;
      }
    }

    &__img {
      @extend %flex-justify-center;
      align-items: center;
      height: auto;
      object-fit: cover;
      min-width: 100px;
      width: 100px;

      img {
        border-radius: 50%;
        height: 100px;
        object-fit: cover;
        width: inherit;
      }

      &__default {
        background-color: $background-img;
        border-radius: 50%;
        height: 100px;
      }
    }
  }

  &__content {
    @extend %title-14;
    color: $primary-color;
    margin-top: 32px;

    a {
      @extend %title-14-600;
      color: $primary-active-color;
      text-decoration: underline !important;
    }

    h2 {
      @extend %title-16;
    }

    h3 {
      @extend %title-15;
    }

    i {
      font-style: italic;
    }

    p {
      min-height: 16px;
    }

    strong {
      @extend %title-14-600;
    }
  }
}

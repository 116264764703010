@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  @extend %title-13;
  position: relative;

  &__title {
    align-items: center;
    border-radius: 8px;
    border: 1px solid $components-border-color;
    color: $primary-color;
    display: flex;
    height: 38px;
    justify-content: flex-start;
    min-width: 134px;
    padding: 8px 12px;
    width: auto;

    &:hover {
      border-color: $primary-active-color;
    }

    .placeholder {
      margin-right: 8px;
    }

    .icon {
      margin-left: 12px;
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
      transition-duration: 0.5s;
      display: inline-block;

      &__open {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        transition-duration: 0.5s;

        path {
          fill-opacity: 1;
          fill: $primary-active-color;
        }
      }
    }
  }

  &__options {
    align-items: flex-start;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0px 0px 4px rgba(20, 36, 39, 0.05), 0px 5px 15px rgba(20, 36, 39, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 155px;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 40px;
    width: 180px;
    z-index: 4;

    .option {
      padding: 5px 12px;
      width: 100%;
    }
    .option:hover {
      background: $hover-option-background;
      color: $hover-option-text;
    }

    &:hover {
      overflow-y: scroll;
    }
  }
}

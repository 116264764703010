@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__header_block {
    @extend %flex-between;
    align-items: flex-end;
    color: $primary-color;
    padding-bottom: 32px;
  }

  &__table_block {
    border-top: 1px solid $border-table-color;
    padding-top: 32px;
  }

  .search_block {
    width: 280px;
  }

  .date_range_block {
    align-items: flex-end;
    display: flex;
    gap: 10px;
    margin-bottom: 4px;

    &__wrapper_export_btn {
      height: 36px;
      width: 140px;
    }

    &__export_btn {
      @extend %title-13-bold;
      background-color: $white;
      border: 1px solid $dark-blue;
      color: $dark-blue;
      padding: 8px 12px;

      &:disabled {
        background-color: $background-disabled;
        border-color: $background-disabled;
        color: $color-disabled;
        cursor: not-allowed;

        svg path {
          fill: $color-disabled;
        }
      }
    }
  }
}

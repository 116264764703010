@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.tab {
  @extend %flex-align;
  @extend %title-13-bold;
  border: 1px solid $components-border-color;
  border-radius: 8px;
  color: $primary-color;
  cursor: pointer;
  padding: 9px 16px;

  &:hover {
    border-color: $primary-active-color;
  }

  &__selected {
    border-color: transparent;
    background-color: $primary-active-color;
    color: $white;
    position: relative;
  }

  &:last-child {
    margin-left: 16px;
  }
}

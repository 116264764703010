@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__header_block {
    @extend %flex-between;
    align-items: flex-start;
    color: $primary-color;
    padding-bottom: 32px;

    .title_block {
      @extend %flex-column;

      &__title {
        @extend %title-24;
        color: $primary-color;
      }
    }

    .date_range_block {
      &__title {
        @extend %title-13;
        color: $primary-color;
        font-weight: 400;
      }
    }
  }

  &__widget_block {
    @extend %flex-between;
  }

  &__choose_partner_block {
    @extend %flex-column;
    margin-bottom: 32px;
    margin-top: 32px;

    &__title {
      @extend %title-13;
      color: $primary-color;
      line-height: 20px;
      margin-bottom: 12px;
    }

    &__slider_block {
      display: flex;

      .no_result {
        @extend %title-13;
        color: rgba(1, 6, 38, 0.4);
        line-height: 20px;
        padding-top: 10px;
        white-space: nowrap;
      }

      .search_box_small {
        min-width: 115px;
        padding-right: 16px;
        transition: width 1s;
        width: 11.5%;
      }

      .search_box_big {
        padding-right: 16px;
        transition: width 1s;
        width: 25%;
      }

      .slider {
        &__big {
          transition: width 1s;
          width: 88%;
        }

        &__small {
          transition: width 1s;
          width: 75%;
        }
      }
    }
  }

  &__partner_block {
    border-bottom: 1px solid $border-color-light;
    border-top: 1px solid $border-color-light;
    display: grid;
    grid-template-columns: 1fr 1.5fr 40%;
    padding: 32px 0;

    &__item {
      @extend %flex-column;
      justify-content: flex-end;
      padding: 0 32px;

      &:first-child {
        border-right: 1px solid $border-color-light;
      }

      .full_name {
        @extend %title-24-700;
        color: $primary-color;
        line-height: 32px;
        margin-bottom: 8px;
      }

      span {
        @extend %title-13;
        color: $primary-color;

        b {
          font-weight: 600;
        }
      }

      .referralCode {
        align-items: center;
        display: flex;

        .copy_btn {
          @extend %flex-align;
          @extend %title-12-500;
          color: $primary-active-color;
          cursor: pointer;
          gap: 4px;
          margin-left: 8px;
        }
      }
    }

    &__search {
      align-items: flex-end;
      display: flex;
      justify-content: flex-end;
      padding-left: 20%;
      width: 100%;
    }
  }

  &__table_block {
    padding-top: 32px;
  }
}

.toaster {
  @extend %title-13;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 300px;
  padding: 16px;
  position: fixed;
  top: 60px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  width: auto;
  z-index: 7;

  &__success {
    border: 0.5px solid #14ab6c;
    color: #14ab6c;
  }
}

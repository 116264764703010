@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.loader {
  // top: 0;
  // right: 0;
  // width: 100vw;
  // height: 100vh;
  // z-index: 1000;
  // margin: 0 auto;
  // position: absolute;
  // background: rgba(0, 0, 0, 0.15);
  // @extend %flex-center;

  &__wrapper {
    width: 100%;
    padding: 24px;
    margin: 0 auto;
    max-width: 280px;
    border-radius: 4px;
    backdrop-filter: blur(4px);
    background: transparent;
    border: 1px solid var($color-disabled);
    @extend %flex-column;

    &__title,
    &__icon {
      color: $primary-active-color;
      text-align: center;

      svg path {
        fill: $primary-active-color;
      }
    }
  }

  &__spinner {
    animation: spin 1s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

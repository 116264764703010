@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  flex: 1;
  margin: 0 auto;
  max-width: 100%;
  min-height: 100vh;
  padding-bottom: 24px;
  padding-top: 40px;
  padding-left: 24%;
}

@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;
}

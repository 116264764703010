@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__profile_title {
    @extend %flex-align;
    justify-content: space-between;
    padding-bottom: 32px;

    &__title {
      .title {
        @extend %title-24;
        color: $primary-color;
        font-weight: 700;
      }

      .email {
        @extend %title-16;
        color: $primary-color;
        margin-top: 6px;
      }
    }
  }

  &__table_block {
    border-top: 1px solid $border-table-color;
    padding-top: 32px;

    &__header {
      @extend %flex-justify-between;

      &__tabs {
        @extend %flex-align;
      }
    }
  }
}

.status {
  @extend %title-13-bold;
  border-radius: 8px;
  padding: 4px 8px;
  text-transform: capitalize;
}

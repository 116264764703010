@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  background-color: $white;
  display: flex;
  padding: 21.5px 16px;

  span {
    @extend %title-13;
    color: $primary-color;
    white-space: nowrap;
  }
}

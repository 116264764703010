@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.btn_container {
  @extend %title-13-bold;
  @extend %flex-center;
  background-color: $dark-blue;
  border: 1px solid $dark-blue;
  border-radius: 8px;
  color: $white;
  height: inherit;
  padding: 8px 16px;
  text-transform: capitalize;
  width: inherit;

  svg {
    margin-right: 8px;
  }

  &:disabled {
    background-color: $background-disabled;
    border-color: $background-disabled;
    color: $color-disabled;
    cursor: not-allowed;

    svg path {
      fill: $color-disabled;
    }
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  background-color: inherit;

  &__header_block {
    @extend %flex-between;
    color: $primary-color;
    padding-bottom: 32px;

    .title_block {
      @extend %flex-column;

      &__title {
        @extend %title-24;
        color: $primary-color;
        font-weight: 400;
      }

      &__token_value {
        display: flex;
        margin-top: 14px;
        min-height: 30px;

        &__value {
          @extend %title-24-700;
          color: $primary-color;
        }

        &__coin {
          @extend %title-16;
          color: $primary-color;
          font-weight: 400;
          margin-left: 8px;
        }
      }
    }

    .date_range_block {
      &__title {
        @extend %title-13;
        color: $primary-color;
        font-weight: 400;
      }
    }
  }

  &__table_block {
    border-top: 1px solid $border-table-color;
    padding-top: 32px;
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  background-color: inherit;

  &__header {
    @extend %flex-column;

    .title {
      @extend %title-16-bold;
    }

    .status_block {
      @extend %flex-align;
      padding: 27px 0 34px;

      &__title {
        @extend %title-13;
        font-weight: 600;
        margin-right: 24px;
      }

      &__mode {
        @extend %title-13-bold;
        border-radius: 8px;
        padding: 4px 8px;
      }
    }
  }

  &__content {
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
    padding: 32px 0;
  }

  &__input_block {
    display: flex;
    gap: 8px;
    padding-top: 24px;
    width: 60%;
  }
}

.tfa_off_wrapper {
  @extend %flex-column-between;
  color: $primary-color;

  &__title {
    @extend %title-13;
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__item {
    @extend %title-13;
  }
}

.tfa_on_wrapper {
  @extend %flex-column-between;
  color: $primary-color;

  &__title {
    @extend %title-13;
    color: $primary-color;
    font-weight: 600;
    margin-bottom: 6px;
  }

  &__item {
    @extend %title-13;
  }

  &__key {
    align-items: flex-start;
    color: $primary-color;
    display: flex;
    margin-top: 32px;

    &__copy {
      @extend %flex-align;
      color: $primary-active-color;
      cursor: pointer;
      gap: 4px;
    }

    &__value {
      @extend %title-13;
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      width: 370px;
    }

    &__qrcode_wrapper {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      margin-left: 112px;
      width: 100px;

      .title {
        @extend %title-13;
        color: $primary-color;
        font-weight: 600;
        margin-bottom: 6px;
      }
    }
  }
}

.toaster {
  @extend %title-13;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 300px;
  padding: 16px;
  position: fixed;
  top: 80px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  width: auto;
  z-index: 7;

  &__error {
    border: 0.5px solid $error-color;
    color: $error-color;
  }

  &__success {
    border: 0.5px solid #14ab6c;
    color: #14ab6c;
  }
}

@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.container {
  @extend %flex-column;
  height: 100vh;
  justify-content: center;
  margin: 0px auto;
  flex-direction: column;
  width: 40%;

  .wrapper {
    @extend %flex-column;
    align-items: center;
    background-color: inherit;
    justify-content: center;
    padding: 8px 46px;

    &__title {
      @extend %title-16-bold;
      color: $primary-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__title_error {
      @extend %title-16-bold;
      color: $error-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__subtitle {
      @extend %title-13;
      color: $primary-color;
      text-align: center;
    }

    &__form_box {
      @extend %flex-column;
      gap: 24px;
      margin-bottom: 48px;
      margin-top: 48px;
      width: 100%;

      .adornment {
        cursor: pointer;
        display: flex;
      }

      .forgot_pass {
        @extend %title-13-bold;
        color: $primary-active-color;
        cursor: pointer;
        text-align: end;
        width: 100%;
      }
    }

    .go_to {
      @extend %title-13-bold;
      color: $primary-active-color;
      cursor: pointer;
      margin-top: 32px;
      text-align: center;
      width: 100%;
    }
  }
}

.toaster {
  @extend %title-13;
  background-color: $white;
  border-radius: 4px;
  box-sizing: border-box;
  color: $error-color;
  max-width: 300px;
  padding: 16px;
  position: fixed;
  top: 80px;
  right: 12px;
  transition: transform 0.6s ease-in-out;
  width: auto;
  z-index: 7;

  &__error {
    border: 0.5px solid $error-color;
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.border {
  border-radius: 8px;
  border: 1px solid $components-border-color;
  margin-right: 6px;

  &:hover {
    border-color: rgba(208, 102, 67, 1);
  }
}
.container {
  align-items: center;
  display: flex;
  gap: 12px;
  padding: 10px 16px;

  &__picture {
    @extend %flex-justify-center;
    align-items: center;
    background-color: $background-img;
    border-radius: 50%;
    height: 30px;
    width: 30px;

    svg {
      height: 22px;
      width: 22px;
    }

    img {
      border-radius: inherit;
      height: inherit;
      object-fit: cover;
      width: inherit;
    }
  }

  &__details {
    @extend %title-13-bold;
    color: $primary-color;
    display: flex;
    flex-direction: column;
    width: 85%;

    &__title {
      color: $primary-color;
      text-transform: capitalize;
      width: 85%;
    }

    &__time {
      opacity: 0.4;
    }
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  @extend %title-13;
  background-color: inherit;
  font-weight: 400;
  width: inherit;

  &__error {
    @extend %title-11;
    color: $error-color;
    margin-top: 4px;
  }

  &__label {
    color: $primary-color;
    margin-bottom: 6px;
  }

  .simple_input {
    background: $white;
    border: 1px solid $components-border-color;
    border-radius: 8px;
    color: $primary-color;
    height: 36px;
    padding: 8px 12px;

    &:focus {
      background-color: $background-disabled;
    }

    &:hover {
      border-color: $primary-active-color;
    }

    &::placeholder {
      color: $fade-color;
    }

    &__error {
      border-color: $error-color !important;
    }
  }

  .helper_text {
    @extend %title-11;
    color: $middle-gray;
    margin-top: 4px;
  }

  .clear {
    cursor: pointer;
    position: absolute;
    right: 3%;
    top: 10px;
  }
}

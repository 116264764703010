@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.title {
  @extend %title-16-bold;
  display: flex;
  color: $primary-color;
  font-weight: 600;
  justify-content: flex-start;
  width: 100%;
}

.sub_title {
  @extend %title-13;
  display: flex;
  color: $primary-color;
  font-weight: 500;
  justify-content: flex-start;
  margin-top: 8px;
  width: 100%;
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper {
  background: $white;
  border-radius: 12px;
  border: 1px solid $border-color-light;
  color: $primary-color;
  display: grid;
  grid-template-columns: 140px 1fr;
  padding: 18px 24px;
  width: 48%;

  &__block_title {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .title {
      @extend %title-16-bold;
    }
    .sub_title {
      @extend %title-13;
    }
  }

  &__block_value {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 52px;

    &__main_value {
      .value {
        @extend %title-24-700;
        line-height: 32px;
      }

      .currency {
        @extend %title-16;
        margin-left: 8px;
      }
    }

    &__secondary_value {
      .sub_value {
        @extend %title-13-bold;
        font-weight: 700;
      }

      .sub_currency {
        @extend %title-13;
        margin-left: 4px;
      }
    }
  }
}

body {
  background: $primary-background;
}

#root {
  height: 100%;
}

.wrapper {
  background: $primary-background;
  font-family: 'Inter', sans-serif;
  height: inherit;
  position: relative;
  width: 100%;
}

.MuiPaper-root {
  border-radius: 0 !important;
  box-shadow: none !important;
}

.MuiTablePagination-spacer {
  flex: none !important;
}

.MuiTablePagination-displayedRows {
  display: none !important;
}

.MuiTablePagination-toolbar {
  margin-top: 24px;
  padding: 0 !important;
}

// Outlined Input
.MuiOutlinedInput-root {
  background: $white !important;
  border: 1px solid $components-border-color !important;
  border-radius: 8px !important;
  box-sizing: border-box !important;
  color: $primary-color !important;
  font-size: inherit !important;
  height: 36px !important;

  &:hover {
    border-color: $primary-active-color !important;
  }

  &.Mui-focused {
    background-color: $background-disabled !important;
  }

  &.Mui-error {
    border-color: $error-color !important;
  }
}

.MuiOutlinedInput-notchedOutline {
  display: none;
}
.MuiOutlinedInput-input {
  @extend %title-13;
  box-sizing: border-box !important;
  padding: auto 12px !important;
}

// Date Picker Styles
.react-datepicker-popper {
  width: 273px !important;
  z-index: 3 !important;
}

.react-datepicker {
  border: none;

  &__header {
    text-align: center;
    background-color: $white;
    border: none;
    border-radius: none;
    padding: 8px 0;
    position: relative;
  }

  &__current-month {
    @extend %title-13-bold;
    color: $primary-color;
    margin-bottom: 16px;
    margin-top: 0;
  }

  &__year-wrapper {
    max-width: none;
  }

  &__day--in-selecting-range:not(.react-datepicker__day--in-range),
  &__day--selected,
  &__day--keyboard-selected,
  &__day--in-selecting-range,
  &__day--in-range,
  &__month-text--keyboard-selected,
  &__month--in-range,
  &__quarter-text--keyboard-selected,
  &__quarter--in-range,
  &__year-text--selected,
  &__year-text--in-selecting-range,
  &__year-text--in-range,
  &__year-text--keyboard-selected {
    @extend %title-13;
    background-color: $primary-active-color;
    border-color: $primary-active-color;
    color: $white !important;
    padding: 4px;

    &:hover {
      background-color: $primary-active-color;
    }
  }

  &__year-text {
    @extend %title-13;
    border: 1px solid $white;
    color: $primary-color;
    padding: 2px;
  }
}

.react-datepicker-year-header {
  @extend %title-13-bold;
  color: $primary-color;
  margin-bottom: 16px;
  margin-top: 0;
}

.react-datepicker__month-wrapper {
  .react-datepicker__month-text {
    &[aria-selected='true'] {
      @extend %title-13;
      background: $primary-active-color;
      border-radius: 6px;
      color: $white;
      padding: 4px;
    }
  }
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  @extend %title-13;
  color: $primary-color;
  padding: 4px;
}

.react-datepicker__quarter-wrapper {
  .react-datepicker__quarter-text {
    @extend %title-13;
    color: $primary-color;
    padding: 4px;
    width: 3rem !important;

    &[aria-selected='true'] {
      @extend %title-13;
      background: $primary-active-color;
      border-radius: 6px;
      color: $white;
      padding: 4px;
    }
  }
}

// full calendar styles
#fc-dom-1 {
  @extend %title-13-bold;
  color: $primary-color !important;
}

.fc-popover-header {
  background: transparent !important;
}

.fc-more-link,
.fc-popover-title {
  @extend %title-13;
  color: $primary-active-color !important;
}

.fc-popover-close {
  color: $primary-active-color !important;
}

.fc-prev-button,
.fc-next-button {
  background: transparent !important;
  border: 1px solid transparent !important;
  color: $primary-color !important;

  &:hover {
    border: 1px solid $primary-active-color !important;
  }

  &:focus {
    box-shadow: none !important;
  }
}

// custom scroll
::-webkit-scrollbar {
  background: $border-color;
  height: 4px;
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: $border-color;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: $border-color;
}

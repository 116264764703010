@import '../../../../styles/vars.scss';
@import '../../../../styles/fonts.scss';
@import '../../../../styles/local.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/common.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.wrapper {
  margin-top: 32px;

  .container {
    .inner {
      border-collapse: collapse;
      border-style: hidden;
      overflow-x: auto;
    }

    &__body {
      &__row {
        border-bottom: 1px solid $border-table-color;

        .ceil {
          @extend %title-13;
          background-color: $white !important;
          color: $primary-color !important;
          font-weight: 500 !important;
          max-width: 100px;
          padding: 16px 0px !important;
          word-break: break-word;

          .commission {
            background: rgba(39, 129, 119, 0.12);
            border-radius: 8px;
            padding: 4px 8px;
          }
        }
      }
    }
  }
}
.table_title {
  @extend %title-16-bold;
  color: $primary-color;
  margin-bottom: 24px;
}

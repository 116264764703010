@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.wrapper_big {
  &__news {
    border-bottom: 1px solid $border-table-color;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;

    &__info {
      @extend %flex-column;
      @extend %title-13;
      color: $primary-color;
      margin-right: 32px;
      max-width: 85%;

      .date {
        @extend %title-12;
        margin-top: 24px;
        opacity: 0.4;
      }

      .link {
        @extend %title-13-bold;
        color: $primary-active-color;
        cursor: pointer;
        line-clamp: 2;
        margin-top: 4px;
      }

      .title {
        @extend %title-16-bold;
        color: $primary-color;
        margin-bottom: 8px;
      }

      .content {
        @extend %title-13;
        color: $primary-color;

        p,
        a,
        u,
        strong,
        i {
          @extend %title-13;
          color: $primary-color;
        }
      }
    }

    &__img {
      @extend %flex-justify-center;
      align-items: center;
      background: $background-img;
      border-radius: 8px;
      min-width: 120px;
      height: 120px;
      width: 120px;

      img {
        border-radius: inherit;
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }
}

.border {
  border-radius: 8px;
  border: 1px solid $components-border-color;
  margin-right: 6px;

  &:hover {
    border-color: rgba(208, 102, 67, 1);
  }
}

.wrapper_small {
  @extend %flex-justify-between;
  background-color: $primary-background;
  border-radius: 8px;
  padding: 16px;

  &__left_side {
    @extend %flex-column-between;

    &__title {
      @extend %title-13-500;
      color: $primary-color;
      max-width: 152px;
    }

    &__date {
      @extend %title-12;
      color: $primary-color;
      opacity: 0.4;
    }
  }

  &__right_side {
    @extend %flex-justify-center;
    align-items: center;
    background-color: $background-img;
    border-radius: 8px;
    height: 82px;
    width: 80px;

    img {
      border-radius: inherit;
      height: inherit;
      object-fit: cover;
      width: inherit;
    }
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.container {
  @extend %flex-column;
  height: 100vh;
  justify-content: center;
  margin: 0px auto;
  flex-direction: column;
  //width: 40%;

  .wrapper {
    @extend %flex-column;
    align-items: center;
    background-color: inherit;
    justify-content: center;
    padding: 8px 46px;

    &__title {
      @extend %title-16-bold;
      color: $primary-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__title_error {
      @extend %title-16-bold;
      color: $error-color;
      margin-bottom: 12px;
      margin-top: 48px;
    }

    &__subtitle {
      @extend %title-13;
      color: $primary-color;
    }

    &__form_box {
      @extend %flex-column;
      gap: 24px;
      margin-bottom: 30px;
      margin-top: 48px;
      width: 100%;

      .adornment {
        cursor: pointer;
        display: flex;
      }
    }
  }
}

@media screen and (min-width: 1000px) {
  .container{
    width: 40%;
  }
}

.error {
  @extend %title-11;
  color: $error-color;
  margin-bottom: 14px;
}

@import '../../../styles/vars.scss';
@import '../../../styles/fonts.scss';
@import '../../../styles/local.scss';
@import '../../../styles/colors.scss';
@import '../../../styles/common.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/typography.scss';

.login__wrapper {
  background-color: $primary-background;
  padding: 0;
}

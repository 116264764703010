@import '../../styles/vars.scss';
@import '../../styles/fonts.scss';
@import '../../styles/local.scss';
@import '../../styles/colors.scss';
@import '../../styles/common.scss';
@import '../../styles/mixins.scss';
@import '../../styles/typography.scss';

.wrapper {
  @extend %flex-column;
  min-height: 100vh;
  padding-right: 40px;
  padding-bottom: 32px;

  &__header {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-bottom: 32px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    padding-bottom: 16px;

    &__item {
      @extend %flex-column;
      gap: 8px;
      width: 50%;

      &__header {
        @extend %flex-justify-between;

        .title {
          @extend %flex-align;
          @extend %title-16-bold;
          color: $primary-color;
        }

        .link {
          @extend %flex-align;
          @extend %title-13-bold;
          color: $primary-active-color;
          cursor: pointer;
        }
      }

      &__news_block {
        min-height: 114px;
      }
    }
  }
}

@import '../../../../styles/vars.scss';
@import '../../../../styles/fonts.scss';
@import '../../../../styles/local.scss';
@import '../../../../styles/colors.scss';
@import '../../../../styles/common.scss';
@import '../../../../styles/mixins.scss';
@import '../../../../styles/typography.scss';

.container {
  .inner {
    border-collapse: collapse;
    border-style: hidden;
    overflow-x: auto;
  }

  &__body {
    &__row {
      border-bottom: 1px solid $border-table-color;

      td {
        @extend %title-13;
        background-color: $white !important;
        color: $primary-color !important;
        font-weight: 500 !important;
        padding: 16px 0px !important;
      }

      &:last-child {
        td {
          border: none;
        }
      }

      &__ceil {
        border-color: $border-table-color !important;
      }
    }
  }
}

.table_title {
  @extend %title-16-bold;
  color: $primary-color;
  margin-bottom: 24px;
}
